import { useI18n } from "vue-i18n"
import setting from "@/config/setting"
import { renderUnit } from "@/utils/common";

export default () => {
    const $t = useI18n().t;

    const columns = {
        "default_col": [{
            title: $t('登录账号'),
            dataIndex: 'user_account',
            fixed: "left",
            width: 100,
            ellipsis: true,
            sorter: true
        }, {
            title: $t('公司名称'),
            dataIndex: 'user_company_name',
            fixed: "left",
            width: 100,
            ellipsis: true,
            sorter: true
        },
        {
            title: $t('订单号'),
            dataIndex: 'po_number',
            fixed: "left",
            width: 200,
            sorter: true,
            slots: {
                customRender: 'po_number'
            }
        },
        {
            title: $t('物流渠道'),
            dataIndex: 'ship_method_name',
            width: 120,
            ellipsis: true,
            sorter: true,
            slots: {
                customRender: "ship_method_name"
            }
        },
        {
            title: $t('收货人'),
            dataIndex: 'name',
            ellipsis: true,
            sorter: true,
            className: "max-address ml-no-wrap",
            width: 130
        },
        {
            title: $t('包裹'),
            dataIndex: 'package_count',
            width: 100,
            sorter: true
        },
        {
            title: $t('重量(Lbs)'),
            dataIndex: 'total_weight',
            width: 100,
            sorter: true,
            customRender: ({
                record
            }) => {
                return `${record.total_weight} ${renderUnit(record, 'weight_unit')}`
            }
        },
        {
            title: $t('跟踪号'),
            dataIndex: 'master_tracking_number',
            sorter: true,
            width: 250,
            slots: {
                customRender: 'tracking_number'
            }
        },
        {
            title: $t('费用'),
            dataIndex: 'charge',
            width: 100,
            sorter: true,
            ellipsis: true,
            customRender: ({
                record
            }) => `${record.charge} ${record.currency}`
        },
        {
            title: $t('差值'),
            width: 80,
            sorter: true,
            ellipsis: true,
            customRender: ({
                record
            }) => record.diff && `${(record.diff != 0 ? record.diff : '')} ${record.currency}`
        },
        {
            title: $t('状态'),
            dataIndex: 'order_status',
            width: 120,
            sorter: true,
            align: 'center',
            slots: {
                customRender: 'status'
            }
        },
        {
            title: $t('备注'),
            dataIndex: 'remark',
            width: 300,
            sorter: true
        },
        {
            title: $t('订单日期'),
            dataIndex: 'created',
            width: 150,
            sorter: true,
            align: 'center',
            fixed: "right",
            slots: {
                customRender: "time_tem"
            }
        },
        {
            title: $t('操作'),
            key: 'action',
            width: 120,
            align: 'center',
            fixed: "right",
            slots: {
                customRender: 'action'
            }
        }
        ],
        "hyd.lbl.morelink56.com": [
            {
                title: $t('登录账号'),
                dataIndex: 'user_account',
                fixed: "left",
                width: 100,
                ellipsis: true,
                sorter: true
            }, {
                title: $t('公司名称'),
                dataIndex: 'user_company_name',
                fixed: "left",
                width: 100,
                ellipsis: true,
                sorter: true
            },
            {
                title: $t("user_order.fields.po_number"),
                key: "po_number",
                dataIndex: "po_number",
                width: 200,
                sorter: true,
                slots: {
                    customRender: "po_number"
                }
            },
            {
                title: $t("share.ship_method"),
                key: "ship_method_name",
                dataIndex: "ship_method_name",
                width: 180,
                sorter: true,
                ellipsis: true,
                slots: {
                    customRender: "ship_method_name"
                }
            },
            {
                title: $t("user_order.fields.ship_to_address_name"),
                key: "name",
                dataIndex: "name",
                sorter: true,
                width: 150
            },
            {
                title: $t("user_order.fields.package_count"),
                key: "package_count",
                dataIndex: "package_count",
                width: 100,
                sorter: true
            },
            {
                title: $t("user_order.fields.weight"),
                key: "total_weight",
                dataIndex: "total_weight",
                width: 100,
                sorter: true,
                customRender: ({
                    record
                }) => {
                    return `${record.total_weight} ${renderUnit(
                        record,
                        "weight_unit"
                    )}`;
                }
            },
            {
                title: $t("user_order.fields.master_tracking_number"),
                key: "master_tracking_number",
                dataIndex: "master_tracking_number",
                sorter: true,
                width: 250,
                slots: {
                    customRender: "tracking_number"
                }
            },
            {
                title: $t("user_order.fields.reference"),
                key: "reference2",
                dataIndex: "reference2",
                width: 200,
                slots: {
                    customRender: "reference"
                }
            },
            {
                title: $t("user_order.fields.charge"),
                key: "charge",
                dataIndex: "charge",
                width: 100,
                sorter: true,
                ellipsis: true,
                customRender: ({
                    record
                }) =>
                    `${record.charge} ${record.currency}`
            },
            {
                title: $t("share.status"),
                key: "order_status",
                dataIndex: "order_status",
                width: 120,
                sorter: true,
                align: "center",
                slots: {
                    customRender: "status"
                }
            },
            {
                title: $t('取消时间'),
                dataIndex: 'void_time',
                width: 150,
                sorter: true,
                align: 'center',
                fixed: "right"
            },
            {
                title: $t("user_order.fields.created"),
                key: "created",
                dataIndex: "created",
                width: 150,
                sorter: true,
                align: "center",
                slots: {
                    customRender: "time_tem"
                },
                fixed: "right"
            },
            {
                title: $t("share.operation"),
                key: "action",
                width: 120,
                align: "center",
                fixed: "right",
                slots: {
                    customRender: "action"
                }
            }
        ],
        'fhjy.lbl.morelink56.com': [{
            title: $t('登录账号'),
            dataIndex: 'user_account',
            fixed: "left",
            width: 100,
            ellipsis: true,
            sorter: true
        }, {
            title: $t('公司名称'),
            dataIndex: 'user_company_name',
            fixed: "left",
            width: 100,
            ellipsis: true,
            sorter: true
        },
        {
            title: $t('订单号'),
            dataIndex: 'po_number',
            fixed: "left",
            width: 200,
            sorter: true,
            slots: {
                customRender: 'po_number'
            }
        },
        {
            title: $t("user_order.fields.reference"),
            dataIndex: "reference2",
            width: 200,
            slots: {
                customRender: "reference"
            }
        },
        {
            title: $t('物流渠道'),
            dataIndex: 'ship_method_name',
            width: 120,
            ellipsis: true,
            sorter: true,
            slots: {
                customRender: "ship_method_name"
            }
        },
        {
            title: $t('收货人'),
            dataIndex: 'name',
            ellipsis: true,
            sorter: true,
            className: "max-address ml-no-wrap",
            width: 130
        },
        {
            title: $t('包裹'),
            dataIndex: 'package_count',
            width: 100,
            sorter: true
        },
        {
            title: $t('重量(Lbs)'),
            dataIndex: 'total_weight',
            width: 100,
            sorter: true,
            customRender: ({
                record
            }) => {
                return `${record.total_weight} ${renderUnit(record, 'weight_unit')}`
            }
        },
        {
            title: $t('跟踪号'),
            dataIndex: 'master_tracking_number',
            sorter: true,
            width: 250,
            slots: {
                customRender: 'tracking_number'
            }
        },
        {
            title: $t('费用'),
            dataIndex: 'charge',
            width: 100,
            sorter: true,
            ellipsis: true,
            customRender: ({
                record
            }) => `${record.charge} ${record.currency}`
        },
        {
            title: $t('差值'),
            width: 80,
            sorter: true,
            ellipsis: true,
            customRender: ({
                record
            }) => record.diff && `${(record.diff != 0 ? record.diff : '')} ${record.currency}`
        },
        {
            title: $t('状态'),
            dataIndex: 'order_status',
            width: 120,
            sorter: true,
            align: 'center',
            slots: {
                customRender: 'status'
            }
        },
        {
            title: $t('备注'),
            dataIndex: 'remark',
            width: 300,
            sorter: true
        },
        {
            title: $t('订单日期'),
            dataIndex: 'created',
            width: 150,
            sorter: true,
            align: 'center',
            fixed: "right",
            slots: {
                customRender: "time_tem"
            }
        },
        {
            title: $t('操作'),
            key: 'action',
            width: 120,
            align: 'center',
            fixed: "right",
            slots: {
                customRender: 'action'
            }
        }
        ],
        'first-star.lbl.morelink56.com': [{
            title: $t('登录账号'),
            dataIndex: 'user_account',
            fixed: "left",
            width: 100,
            ellipsis: true,
            sorter: true
        }, {
            title: $t('公司名称'),
            dataIndex: 'user_company_name',
            fixed: "left",
            width: 100,
            ellipsis: true,
            sorter: true
        },
        {
            title: $t('订单号'),
            dataIndex: 'po_number',
            fixed: "left",
            width: 200,
            sorter: true,
            slots: {
                customRender: 'po_number'
            }
        },
        {
            title: $t("user_order.fields.reference"),
            dataIndex: "reference2",
            width: 200,
            slots: {
                customRender: "reference"
            }
        },
        {
            title: $t('物流渠道'),
            dataIndex: 'ship_method_name',
            width: 120,
            ellipsis: true,
            sorter: true,
            slots: {
                customRender: "ship_method_name"
            }
        },
        {
            title: $t('收货人'),
            dataIndex: 'name',
            ellipsis: true,
            sorter: true,
            className: "max-address ml-no-wrap",
            width: 130
        },
        {
            title: $t('包裹'),
            dataIndex: 'package_count',
            width: 100,
            sorter: true
        },
        {
            title: $t('重量(Lbs)'),
            dataIndex: 'total_weight',
            width: 100,
            sorter: true,
            customRender: ({
                record
            }) => {
                return `${record.total_weight} ${renderUnit(record, 'weight_unit')}`
            }
        },
        {
            title: $t('跟踪号'),
            dataIndex: 'master_tracking_number',
            sorter: true,
            width: 250,
            slots: {
                customRender: 'tracking_number'
            }
        },
        {
            title: $t('费用'),
            dataIndex: 'charge',
            width: 100,
            sorter: true,
            ellipsis: true,
            customRender: ({
                record
            }) => `${record.charge} ${record.currency}`
        },
        {
            title: $t('差值'),
            width: 80,
            sorter: true,
            ellipsis: true,
            customRender: ({
                record
            }) => record.diff && `${(record.diff != 0 ? record.diff : '')} ${record.currency}`
        },
        {
            title: $t('状态'),
            dataIndex: 'order_status',
            width: 120,
            sorter: true,
            align: 'center',
            slots: {
                customRender: 'status'
            }
        },
        {
            title: $t('备注'),
            dataIndex: 'remark',
            width: 300,
            sorter: true
        },
        {
            title: $t('订单日期'),
            dataIndex: 'created',
            width: 150,
            sorter: true,
            align: 'center',
            fixed: "right",
            slots: {
                customRender: "time_tem"
            }
        },
        {
            title: $t('操作'),
            key: 'action',
            width: 120,
            align: 'center',
            fixed: "right",
            slots: {
                customRender: 'action'
            }
        }
        ]
    }
    return {
        columns: columns[setting.env] || columns['default_col']
    }
}